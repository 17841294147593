import PlanColumn from "./PlanColumn";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../store/session";
import { useState } from "react";
import { Modal } from "../context/Modal";
import AccountDelete from "./AccountDelete";
import DashboardHeader from "./DashboardHeader";
import { useHistory } from "react-router-dom";
import { openInNewTab, prettyDate } from "./Utils";
import { useEffect } from "react";
import { getCustAndSub } from "./apiCalls";
import ChangePlan from "./ChangePlan";
import { BUTTON_CLASS, BUTTON_CLASS_DELETE, BUTTON_CLASS_WHITE } from "../constants";


const Account = () => {

  const history = useHistory();
  const currentUser = useSelector(getCurrentUser);
  const plan = currentUser.plan.toLowerCase();
  const [subscription, setSubscription] = useState({});
  const [customer, setCustomer] = useState({});
  const [fetchSubFlag, setFetchSubFlag] = useState(1);
  const [showAccountDeleteModal, setShowAccountDeleteModal] = useState();
  const [showChangePlanModal, setShowChangePlanModal] = useState(false);
  const discountPercent = subscription?.discount?.coupon?.percent_off
  const monthlyPriceRaw = subscription?.plan?.amount


  const calculatedMonthlyPrice = () => {
    let monthlyPrice = 10;
    if (monthlyPriceRaw && discountPercent) {
      const percentToPay = (100 - discountPercent) / 100
      monthlyPrice = monthlyPriceRaw * percentToPay / 100
    } else if (monthlyPriceRaw) {
      monthlyPrice = monthlyPriceRaw / 100
    }
    return parseFloat(monthlyPrice).toFixed(2);
  }

  console.log('subscription is:', subscription)

  const handleGoHome = (e) => {
    e.preventDefault();
    history.push('/');
  }

  const fetchSubscription = async () => {
    const res = await getCustAndSub();
    console.log('Customer fetch message: ', res.message);
    if (res.sub) setSubscription(res.sub);
    if (res.cus) setCustomer(res.cus);
  }

  useEffect(() => {
    fetchSubscription();
  }, [fetchSubFlag])

  const handleGoManageBooking = (e) => {
    const test_portal_link = 'https://billing.stripe.com/p/login/test_4gw4gh3KR0PJ5MIdQQ'
    const prod_portal_link = 'https://billing.stripe.com/p/login/5kA7sI6766nyfNS9AA'
    openInNewTab(e, process.env.NODE_ENV == 'production' ? prod_portal_link : test_portal_link);
  }

  const premiumPlanInfo = {
    status: 'premium',
    name: 'Premium plan',
    price: `$${calculatedMonthlyPrice()}`,
    cards: 'Unlimited contact cards',
    expiry: 'Auto-renews: ' + prettyDate(currentUser.premiumExpiryDate),
    description: [
      'You can manage unlimited contact cards with this plan.',
      'If you cancel, you’ll be limited to 10 contact cards.',
      'You will keep the 10 newest contact cards by default.',
      'Reactivating your premium plan will restore removed cards.'
    ]
  }

  const expiringPlanInfo = {
    status: 'expiring',
    name: 'Premium plan',
    price: 'Canceled',
    cards: 'Unlimited contact cards',
    expiry: 'Ends: ' + prettyDate(currentUser.premiumExpiryDate),
    description: [
      'You can manage unlimited contact cards until your monthly subscription expires.',
      'On that date, you will keep the 10 newest cards by default.',
      'You will not lose any contact cards if you reactivate your premium plan before your monthly subscription expires. ',
      'Reactivate your premium plan for $10/month.'
    ]
  }

  const freePlanInfo = {
    status: 'free',
    name: 'Free plan',
    price: '',
    cards: 'Limited to 10 contact cards',
    description: [
      'Upgrade to a premium plan for unlimited contact cards. ',
      'Premium plan: $10/month'
    ]
  }

  let userPlanInfo = {};

  if (plan === 'free') {
    userPlanInfo = freePlanInfo;
  } else {
    if (subscription.cancel_at_period_end) {
      userPlanInfo = expiringPlanInfo;
    } else {
      userPlanInfo = premiumPlanInfo;
    }
  }

  return (

    <div className="flex flex-col w-full h-screen items-start">
      <DashboardHeader settings={true} />
      <div className="flex flex-row justify-start cursor-pointer text-brand-primary ml-8 font-semibold h-12 items-center" >
        <button onClick={handleGoHome} className="group h-full flex flex-row items-center justify-center p-4 ">
          <i className="fa-solid fa-arrow-left hover:text-bp2 active:text-bp3 mr-4 text-brand-primary group-hover:text-bp2 group-active:text-bp3"></i>
          <p className="text-brand-primary group-hover:text-bp2 group-active:text-bp3">
          Back to Board
          </p>
        </button>
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="flex flex-col items-center h-screen">
            <p className="text-4xl font-semibold m-8">Account</p>
            <div className="flex flex-col items-center bg-background-disabled min-w-[450px] w-[450px] p-6">
              <div className="flex flex-col justify-center items-start mb-12 w-full">
                <p className="text-3xl mb-2">{userPlanInfo.name}</p>
                <p className="font-bold">{userPlanInfo.cards}</p> 
                {plan === 'premium' && 
                <div className="flex flex-col items-start justify-center">
                  <p>Monthly Subscription: {userPlanInfo.price}</p>
                  <p className="mb-2">{userPlanInfo.expiry}</p>
                  
                </div>
                }
                {userPlanInfo.description.map((desc, idx) => (
                  <p key={idx}>{desc}</p>
                ))}
                <div className="flex flex-row justify-end items-center w-full mt-6">
                  {(userPlanInfo.status === 'expiring') &&
                  <button onClick={handleGoManageBooking} className={BUTTON_CLASS}>Reactivate premium plan</button>
                  }
                  {(userPlanInfo.status !== 'expiring') &&
                  <button onClick={setShowChangePlanModal} className={BUTTON_CLASS}>
                    {userPlanInfo.status === 'premium' ? 'Cancel premium plan' : 'Upgrade to premium'}
                  </button>
                  }
                </div>
              </div>
              {currentUser.stripeId &&
              <div className="flex flex-col justify-center items-start w-full mb-12">
                <p className="text-3xl mb-2">Manage billing</p>
                <p>You can update your payment information.</p>
                <p>You'll be taken to the Stripe payment processing platform to manage your billing.</p>
                <p>Stripe will ask for the email address used to set up your billing: <span className="font-semibold">{customer ? customer.email : 'Email not available'}</span></p>
                <div className="flex flex-row justify-end items-center w-full mt-6">
                  <button className={BUTTON_CLASS_WHITE} onClick={handleGoManageBooking}>Manage Billing</button>
                </div>
              </div>
              }
              <div className="flex flex-col justify-center items-start w-full">
                <p className="text-3xl mb-2">Delete Account</p>
                <p>You will immediately lose access to all your contact cards.</p>
                <p>The information they contain will be lost. </p>
                <p>You won't be charged a monthly fee anymore, but this month's fee won't be refunded.</p>
                <div className="flex flex-row justify-end items-center w-full mt-6">
                  <button className={BUTTON_CLASS_DELETE} onClick={e => setShowAccountDeleteModal(true)}>Delete account</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showAccountDeleteModal && <Modal children={<AccountDelete setShowAccountDeleteModal={setShowAccountDeleteModal}/>} />}
        {showChangePlanModal && <Modal children={<ChangePlan setFetchSubFlag={setFetchSubFlag} setShowChangePlanModal={setShowChangePlanModal} plan={plan} />}/>}

    </div>

  )

}

export default Account;