import { useState } from "react";
import { BUTTON_CLASS } from "../constants";
import toast from "react-hot-toast";
import csrfFetch from "../store/csrf";
import Spinner from "./Spinner";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, getLoading } from "../store/loading";


const FollowupDraft = ({contact}) => {
  
  const loading = useSelector(getLoading);
  const dispatch = useDispatch();
  const [followupText, setFollowupText] = useState('');

  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Text copied to clipboard!");
  }

  const openEmailClient = (recipient, body) => {
    if (recipient && recipient.length && body && body.length) {
      const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent("Following-up")}&body=${encodeURIComponent(body)}`;
      window.open(mailtoLink, '_blank');
    } else {
      toast.error("Recipient email or body empty.")
    }
  }

  const generateFollowup = async (e) => {
    dispatch(setLoading(1));
    try {
      const res = await csrfFetch(`/api/geminis/`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({id: contact.id})
      });

      if (res.ok) {
        const data = await res.json();
        const followUpText = data.response;
        toast.success(`Magical elves just crafted an elegant followup message for you!`);
        setFollowupText(followUpText);
      } else {
        toast.error(`Error during generate followup call. Status: ${res.status}`)
      };
      
    } catch (e) {
      toast.error(`Error during generate followup call: ${e.get}`)
    }
    dispatch(setLoading(0));
  }

  return (

    <div className="interaction-index flex flex-col items-start w-full p-3 overflow-auto h-full">
        <div className="flex flex-col w-full my-3 items-center">
          <button onClick={generateFollowup} className={BUTTON_CLASS + 'w-[300px] mb-5'}>
            {loading ? <Spinner/> : "Generate Followup Message"}
          </button>
          <label className="w-full text-xl" htmlFor="notes">Followup Message
            <textarea className='mt-2 drop-shadow bg-white border-none h-[250px] w-full text-left resize-none relative p-1 focus:outline-none focus:ring-2 focus:ring-brand-primary rounded' type="textarea" id="notes" maxLength="1000" value={followupText} onChange={(e) => setFollowupText(e.target.value)} />
          </label>
          <p className="text-right w-full">{followupText.length} / 1000</p>
        </div>
        <div className="flex flex-row w-full items-center justify-center">
          <button onClick={e => copyTextToClipboard(followupText)} className={BUTTON_CLASS + 'mr-4'}>Copy Text</button>
          <button onClick={e => {openEmailClient(contact.email, followupText)}} className={BUTTON_CLASS}>Open Email Client</button>
        </div>
    </div>

  )

}

export default FollowupDraft;