import { useState } from "react";
import { Modal } from "../context/Modal";
import ContactDelete from "./ContactDelete";
import {openInNewTab, shortenText} from "./Utils";
import emptyPic from "../assets/profile_pic_empty.svg";
import { BODY_BOLD, TEXT_SMALL } from "../constants";
import exclaimIcon from '../assets/exclaim.svg';
import { Tooltip } from 'react-tooltip';


const ContactCard = ({contact, setShowContactShow, snapshot, setContact}) => {

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [stopPropagation, setStopPropagation] = useState(false);
  const [imageError, setImageError] = useState(false);
  const draggingStyleAddition = !snapshot.isDragging ? 'border-white' : 'border-success-green';
  const expiredPhotoHTML = "This contact updated their profile image on LinkedIn.<br />Click to open their LinkedIn profile in a new tab.<br />Use our extension to auto update the image."

  const handleDelete = (e) => {
    e.preventDefault();
    if(e && e.stopPropagation) {
      e.stopPropagation();
    }
    setShowDeleteModal(true);
    setStopPropagation(true);
  }

  const handleShowContact = (e) => {
    if (!stopPropagation) {
    e.preventDefault();
    setContact(contact);
    setShowContactShow(true);
    }
  }


  return (

    <div id={`contact-card-${contact.id}`} className={`hover:border-brand-primary flex flex-col rounded-lg w-full bg-white p-3 mb-2 cursor-pointer h-[150px] justify-between border-solid border-2 ` + draggingStyleAddition} onClick={handleShowContact}>
      <div className="flex flex-row">
        <div className="bg-[#E3F2FD] rounded-full w-12 h-12 flex items-center justify-center">
          {!imageError && <img className="rounded-full w-12 h-12 object-contain" onError={e => setImageError(true)} src={contact.photoUrl ? contact.photoUrl : emptyPic} />}
          {imageError && 
          <>
            <img className="rounded-full w-8 h-8 object-contain cursor-pointer hover:bg-bp5 " onClick={e => openInNewTab(e, contact.linkedIn)} src={exclaimIcon} data-tooltip-id="expired-photo" data-tooltip-html={expiredPhotoHTML} />
            <Tooltip id="expired-photo" />
          </>
          }
        </div>
        <div className="flex flex-col ml-3">
          <p className={BODY_BOLD}>{shortenText(contact.name, 18)}</p>
          <p className={TEXT_SMALL}>{shortenText(contact.title, 23)}</p>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <p className={TEXT_SMALL}>{shortenText(contact.company, 33)}</p>
      </div>
      <div className="flex flex-row justify-between mt-3">
        <i className="fa-solid fa-trash-can text-xl hover:text-success-green" onClick={handleDelete}></i>
        {contact.linkedIn && <i onClick={e => openInNewTab(e, contact.linkedIn)} className="fa-brands fa-linkedin text-xl text-[#0B65C2] hover:text-[#0655A7] active:text-[#04305D]"></i>}
      </div>
    {showDeleteModal && <Modal children={<ContactDelete setStopPropagation={setStopPropagation} setShowContactShow={setShowContactShow} setShowDeleteModal={setShowDeleteModal} contactId={contact.id}/>}/>}
    </div>

  )

}

export default ContactCard;