export const checkErrors = async (res, setter) => {
  let data;
  let errors;
  try {
    // .clone() essentially allows you to read the response body twice
    data = await res.clone().json();
  } catch {
    data = await res.text(); // Will hit this case if the server is down
  }
  if (data?.errors) {
    setter(data.errors);
    errors = data.errors;
  } else if (data) {
    setter([data]);
    errors = [data];
  } else {
    setter([res.statusText]);
    errors = [res.statusText];
  }
  return errors;
};

export const openInNewTab = (e, url) => {
  if (e) e.stopPropagation();
  const fullUrl = url.includes('https://') || url.includes('http://') ? url : 'https://' + url;
  const newWindow = window.open(fullUrl, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null;
}

export const validInputs = (password, confirmPassword, name) => {
  let errors = [];
  if (!hasUpperCase(password)) errors.push('');
  if (!hasLowerCase(password)) errors.push('');
  if (!hasNumber(password)) errors.push('');
  if (!hasEightChars(password)) errors.push('');
  if (password !== confirmPassword) errors.push('Passwords must match.');
  if (!name || !name.length) errors.push('Name cannot be empty.');
  return errors;
}

export const hasUpperCase = (str) => {
  return (/[A-Z]/.test(str));
}

export const hasLowerCase = (str) => {
  return (/[a-z]/.test(str));
}

export const hasNumber = (str) => {
  return (/[0-9]/.test(str));
}

export const hasEightChars = (str) => {
  return (str.length >= 8);
}

export const isEmail = emailAdress => {
  let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return emailAdress.match(regex) ? true : false; 
}

export const prettyDate = (date, needShift = false) => {
  const dateObj = new Date(date);
  const localDate = needShift ? new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000) : dateObj;
  const newDate =  localDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  return newDate;
}

export const togglePasswordVisibility = (stateVal, stateSetter, inputEle) => () => {
  stateVal === 'password' ? stateSetter('text') : stateSetter('password');
  inputEle.focus();
}

export const shortenText = (text, length) => {
  if (text && text.length > length) {
    return text.slice(0, length) + "...";
  }
  return text;
}

export const validateUrl = (url) => {
  let regex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  return url.match(regex) ? true : false;
}

export const updateFlagAndVariable = (e, setFlag, setter) => {
  setFlag(true);
  setter(e.target.value);
}

export const updateArrayAndVariable = (e, setArray, setter, id) => {
  setArray(prevArray => [...prevArray, id]);
  setter(e.target.value);
}

export const removeIdFromStateArray = (id, setter) => {
  setter(prevArray => prevArray.filter(ele => ele !== id));
}