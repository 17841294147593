export const TEXT_INPUT_CLASS = "drop-shadow bg-white border-none h-8 w-full mb-4 p-2 "
export const TEXT_INPUT_ERROR_CLASS = "drop-shadow bg-white border-2 border-solid border-error-red h-8 w-full mb-4 p-2 "
export const BUTTON_CLASS = "drop-shadow font-medium py-2 px-4 rounded bg-brand-primary text-white hover:bg-bp2 active:bg-bp3 disabled:bg-text-secondary "
export const BUTTON_CLASS_WHITE = "drop-shadow font-medium py-1.5 px-4 rounded bg-white border-solid border-[3px] border-brand-primary text-brand-primary hover:bg-bp10 active:bg-bp9 disabled:border-text-secondary disabled:text-text-secondary "
export const BUTTON_CLASS_DELETE = "border-[3px] font-medium border-solid rounded py-1.5 px-4 border-error-red bg-white text-error-red hover:text-error2 hover:border-error2 hover:bg-[#FFF2F2] active:text-error3 active:border-error3 active:bg-[#FFDEDE] "
export const BUTTON_CLASS_ADD_CONTACT = "rounded font-medium py-2 px-4 bg-bp3 text-white hover:bg-bp2 active:bg-brand-primary disabled:bg-background-disabled "
export const BUTTON_CLASS_ADD_COLUMN = "drop-shadow-lg border-none font-medium rounded py-2 px-4 bg-white text-text-primary hover:bg-bp10 active:bg-bp9 disabled:bg-background-disabled "

export const sub2Bold = "text-[20px] font-bold "
export const sub2Med = "text-[20px] font-medium "
export const COLUMN_HEADER = "text-[18px] font-semibold text-white tracking-wider "
export const TEXT_SMALL = "text-[14px] font-normal "
export const TEXT_SMALL_500 = "text-[14px] font-medium "
export const BODY_BOLD = "text-[16px] font-bold "
export const SUBTITLE = "text-[24px] font-medium "
export const SUBTITLE_BOLD = "text-[24px] font-bold "
export const BODY = "text-[14px] lg:text-[16px] font-medium "
export const HEADER_1 = "text-[50px] leading-[60px] lg:text-[64px] lg:leading-[70px] font-bold "
export const HEADER_2 = "text-[40px] font-bold leading-[48px] "
export const HEADER_3 = "text-[30px] font-bold "
export const LINK_TEXT = "text-[14px] font-bold tracking-wide underline-offset-4 text-link-blue underline "

export const DROPDOWN_ITEM = "flex flex-col justify-center rounded dropdown-item z-50 position-absolute h-10 p-[8px] bg-white w-full hover:bg-bp10 hover:px-[6px] active:px-[6px] hover: border-brand-primary hover:border-2 active:bg-bp9 active:border-none"

export const INPUT_CLASS = "drop-shadow bg-white border-none h-10 p-2 active:border-brand-primary active-border-2 hover:border-brand-primary rounded focus:outline-none focus:ring-2 focus:ring-brand-primary "


export const TERMS_SERVICE = [
  {
    title: 'Acceptance of Terms',
    content: [
      'By using our service, you agree to these Terms of Use. If you do not agree, do not use the service.'
    ]
  },
  {
    title: 'Subscription and Billing',
    content: [
      'You will be billed monthly or annually, depending on the plan you choose.',
      'Payments are non-refundable.',
      'You can upgrade or downgrade your plan at any time. Changes will take effect at the start of the next billing cycle.'
    ]
  },
  {
    title: 'Account Information',
    content: [
      'You must provide accurate information when creating an account.',
      'You are responsible for maintaining the confidentiality of your account and password.',
    ]
  },
  {
    title: 'Use of Service',
    content: [
      'You agree to use the service only for lawful purposes.',
      'You must not misuse, hack, or damage the service.',
    ]
  },
  {
    title: 'Cancellation',
    content: [
      'You can cancel your subscription at any time through your account settings.',
      'Upon cancellation, you will lose access to the service at the end of your current billing period.',
    ]
  },
  {
    title: 'Changes to the Service',
    content: [
      'We may modify or discontinue the service at any time, with or without notice.'
    ]
  },
  {
    title: 'Limitation of Liability',
    content: [
      'We are not liable for any direct, indirect, incidental, or consequential damages arising from your use of the service.',
      'We cannot offer refunds for any direct, indirect, incidental, or consequential damages arising from your use of the service.',
      'It’s at your discretion to use the service per LinkedIn’s user agreement.'
    ]
  },
  {
    title: 'Contact Information',
    content: [
      'For any questions about these Terms of use, please contact us at support@untewrk.com.'
    ]
  },
]