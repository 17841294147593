import logo from '../assets/unetwrk_long_logo.svg';
import { useHistory } from 'react-router-dom';
import { BUTTON_CLASS, BUTTON_CLASS_WHITE } from '../constants';

const LandingHeader = () => {

  const history = useHistory();

  const handleGoPage = (address) => {
    history.push(address);
  }

  return (

    <div className="flex flex-row items-center justify-between p-2 px-8 w-screen border-solid border-b-4 border-brand-primary h-[70px] py-4 sticky top-0 left-0 bg-white z-[5000] ">
      <img className='cursor-pointer h-[40px]' onClick={e => handleGoPage('/')} src={logo} alt="Unetwrk Logo" />
      <div>
        <button onClick={e => handleGoPage('/login')} className={BUTTON_CLASS_WHITE}>Log in</button>
        <button onClick={e => handleGoPage('/signup')} className={BUTTON_CLASS + 'ml-6'}>Get started</button>
      </div>
    </div>

  )

}

export default LandingHeader;