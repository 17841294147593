import { BODY, BODY_BOLD, BUTTON_CLASS, BUTTON_CLASS_WHITE, HEADER_3 } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { getLoading, setLoading } from "../store/loading";
import csrfFetch from "../store/csrf";
import { getCurrentUser } from "../store/session";
import toast from "react-hot-toast";
import Spinner from "./Spinner";
import { updateUser } from "../store/users";


const LimitReached = ({setShowLimitModal, trialExpired}) => {

  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const loading = useSelector(getLoading);

  const headerText = trialExpired ? [
    "Your free trial expired.",
    "Continue growing and maintaining your network with a premium account.",
    "You can continue with a free account. You will keep the 10 newest contact cards by default."
  ] : [
    "Your network has grown!",
    "You've met the limit of 10 contact cards.",
    "Upgrade to the premium plan to continue growing your network."
  ]

  const  handleChoosePremium = async () => {
    dispatch(setLoading(1));
    if (currentUser.freeTrial) dispatch(updateUser({id: currentUser.id, free_trial: false}));
    let response = await csrfFetch("/api/create-checkout-session", {
      method: "POST",
      body: JSON.stringify({userId: currentUser.id, plan: 'premium'}),
    });
    setShowLimitModal(false);
    if (response.ok) {
    if (response.status == 201) toast.success('There is an active subscription associated with this account. Please manage your subscription through the Stripe portal.');
      let link = response.headers.get('Location');
      window.open(link, '_blank');
    } else {
      alert('There was an error upgrading your plan. Please try again or contact us for assistance.')
    }
    dispatch(setLoading(0));
  }

  const handleChooseFree = () => {
    if (currentUser.freeTrial) dispatch(updateUser({id: currentUser.id, free_trial: false}));
    setShowLimitModal(false);
  }


  return (
    <div className="flex flex-col items-center justify-center bg-white rounded border-2 border-brand-primary">
      <div className="flex flex-col items-center justify-center w-[440px] h-[450px] rounded p-8">
        <p className={HEADER_3}>{headerText[0]}</p>
        <div className="flex flex-col items-start mt-3">
          <p className={BODY_BOLD}>{headerText[1]}</p>
          <p className={BODY}>{headerText[2]}</p>
        </div>
        <div className="flex flex-col items-center justify-center bg-pale-green rounded p-4 min-w-[260px] mt-4">
          <div className="flex flex-col items-center justify-center bg-white drop-shadow w-full rounded p-2 mb-3">
            <p className="text-xl font-semibold">Premium</p>
            <p>$10/month</p>
          </div>
          <div className="flex flex-col items-start justify-center bg-white drop-shadow w-full rounded p-2">
            <p><span className="font-semibold">Unlimited </span>contact cards</p>
            <p>Chrome extension</p>
          </div>
        </div>
        <div className="flex flex-row justify-center w-full mt-8">
          <button onClick={handleChooseFree} className={BUTTON_CLASS_WHITE}>
            Maintain 10 contacts
          </button>
          <button onClick={handleChoosePremium} className={BUTTON_CLASS + 'min-w-[150px] ml-8'}>
            {loading === 1 ? <Spinner /> : 'Upgrade'}
          </button>
        </div>
      </div>
    </div>
  )

}

export default LimitReached;