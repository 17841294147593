import csrfFetch from "../store/csrf";

export const forgotPassCall = (email) => {
  return csrfFetch('/api/forgot-password', {
    method: 'POST',
    body: JSON.stringify({ email })
  });
} 

export const getCustAndSub = async () => {
  const resp = await csrfFetch('/api/retreive-customer-info');
  const customerAndSub = await resp.json();
  return customerAndSub;
}