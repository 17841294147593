import toast from "react-hot-toast";
import csrfFetch from "./csrf";


export const ADD_ALL_NOTIFICATIONS = 'ADD_ALL_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

const addAllNotifications = (notifications) => ({
  type: ADD_ALL_NOTIFICATIONS,
  notifications
});

const addNotification = (notification) => ({
  type: ADD_NOTIFICATION,
  notification
});

const removeNotification = (notificationId) => ({
  type: REMOVE_NOTIFICATION,
  notificationId
});


export const getNotifications = (state = {}) => {
  if (!state.notifications) return [];
  return Object.values(state.notifications);
};

export const getNotificationForInteraction = (interactionId) => (state = {}) => {
  if (!state.notifications) return null;
  return Object.values(state.notifications).filter(notification => {
    return notification.interactionId === interactionId;
  })[0];
};

export const getUserNotifications = (userId) => (state = {}) => {
  if (!state.notifications) return [];
  const usernotifications = Object.values(state.notifications).filter(notification => {
    return notification.userId === userId;
  });
  return usernotifications;
};

export const fetchNotifications = () => async dispatch => {
  const res = await fetch(`/api/notifications`);

  if (res.ok) {
    const notifications = await res.json();
    dispatch(addAllNotifications(notifications));
  };
};

export const fetchUserNotifications = (userId) => async dispatch => {
  const res = await fetch(`/api/notifications`);

  if (res.ok) {
    const notifications = await res.json();
    const notificationsArray = Object.values(notifications)
    const userNotifications = notificationsArray.filter(notification => notification.userId === userId);
    let reducedNotifications = {};
    userNotifications.forEach(notification => {
      reducedNotifications[notification.id] = notification;
    });
    dispatch(addAllNotifications(reducedNotifications));
  };
};

export const createNotification = (notification) => async dispatch => {
  try {

    const res = await csrfFetch(`/api/notifications`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(notification)
    });
    
    if (res.ok) {
      const newnotification = await res.json();
      dispatch(addNotification(newnotification));
    }
  } catch (e) {
    toast.error('Error creating notification.');
  }
};

export const deleteNotification = (notificationId) => async dispatch => {
  const res = await csrfFetch(`/api/notifications/${notificationId}`, {
    method: 'DELETE'
  });

  if (res.ok) {
    dispatch(removeNotification(notificationId));
  };
};

export const updateNotification = (notification) => async dispatch => {
  const res = await csrfFetch(`/api/notifications/${notification.id}`, {
    method: 'PATCH',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(notification)
  });

  if (res.ok) {
    const updatedNotification = await res.json();
    dispatch(addNotification(updatedNotification));
  }
};

const notificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_ALL_NOTIFICATIONS:
      return action.notifications;
    case ADD_NOTIFICATION:
      return {...state, [action.notification.id]: action.notification};
    case REMOVE_NOTIFICATION:
      let newState = {...state};
      delete newState[action.notificationId]
      return newState;
    default:
      return state;
  }
};

export default notificationsReducer;