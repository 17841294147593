import React, { useState } from "react";
import { updateArrayAndVariable } from "./Utils";
import { DROPDOWN_ITEM } from "../constants";


const ContactMethodChoiceDropdown = ({key = "new", contactMethod, setContactMethod, setDataChanged}) => {

  const [showMenu, setShowMenu] = useState(false);

  const contactMethods = [
    "LinkedIn message",
    "Virtual call",
    "Email",
    "Phone call",
    "Slack",
    "Discord",
    "Other (add in notes)"
  ];

  const handleChooseMethod = e => {
    e.preventDefault();
    setDataChanged(prevArray => [...prevArray, key]);
    setContactMethod(e.target.innerHTML);
    setShowMenu(false);
  }


  return (
    <div onClick={e => setShowMenu(!showMenu)} tabIndex="1" onBlur={e => setShowMenu(false)} className="rounded drop-shadow-lg bg-white border-none h-10 w-full cursor-pointer z-50 relative">
      <div className='flex flex-row w-full justify-between p-2 mb-2'>
        <h4>{contactMethod ? contactMethod : "Select Method"}</h4>
        <i className="fa-solid fa-angle-down mt-1"></i>
      </div>
      <ul className='dropdown-list z-50 w-full bg-white rounded'>
      {showMenu && contactMethods.map(method => (
          <li key={method} className={DROPDOWN_ITEM} onClick={handleChooseMethod}>{method}</li>
      ))}
      </ul>      
    </div>

  );
};

export default ContactMethodChoiceDropdown;