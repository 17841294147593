import emptyPic from "../assets/profile_pic_empty.svg";
import uploadIcon from "../assets/upload_icon.svg";
import ContactUpdate from "./ContactUpdate";
import { useEffect, useState } from "react";
import InteractionIndex from "./InteractionIndex";
import { useSelector, useDispatch } from "react-redux";
import { fetchTiers, getUserTiers } from "../store/tiers";
import { getCurrentUser } from "../store/session";
import { fetchInteractions } from "../store/interactions";
import Timeline from "./Timeline";
import { useRef } from "react";
import { Modal } from "../context/Modal";
import ImageCrop from "./ImageCrop";
import ColumnChoiceDropdown from "./ColumnChoiceDropdown";
import { shortenText } from "./Utils";
import { BUTTON_CLASS_WHITE, sub2Bold, sub2Med } from "../constants";
import CloseContact from "./CloseContact";
import FollowupDraft from "./FollowupDraft";


const CONTACT_INFO_TEXT = "Contact Information";
const INTERACTION_NOTES_TEXT = "Interaction Notes";
const FOLLOWUP_DRAFT_TEXT = "Followup Message - (beta)"
const UNDERLINE_STYLE = "cursor-pointer border-solid border-b-4 border-green-900 m-5 mb-2";
const NON_UNDERLINE_STYLE = "cursor-pointer m-5 mb-2";

const ContactShow = ({contact, setShowContactShow, order}) => {

  
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const tiers = useSelector(getUserTiers(user.id));
  const currentContact = useSelector(state => state.contacts[contact.id]);
  const [columnOrder, setColumnOrder] = useState(contact.columnOrder != undefined ? contact.columnOrder : order);
  const [contentChoice, setContentChoice] = useState(CONTACT_INFO_TEXT);
  const [photo, setPhoto] = useState(null);
  const [displayImage, setDisplayImage] = useState(true)
  const [showImageCrop, setShowImageCrop] = useState(false);
  const [showCloseContactModal, setShowCloseContactModal] = useState(false);
  const [contactdataChanged, setContactDataChanged] = useState(false);
  const [interactionDataChanged, setInteractionDataChanged] = useState([]);
  
  useEffect(() => {
    dispatch(fetchTiers());
    dispatch(fetchInteractions());
  }, [dispatch])

  const inputFile = useRef(null);

  const chooseContent = (e) => {
    setContentChoice(e.target.innerText);
  }

  const imageClick = () => {
    inputFile.current.click();
  }

  const handleFile = (e) => {
    const picture = e.currentTarget.files[0];
    setPhoto(URL.createObjectURL(picture));
    e.currentTarget.value = null;
    setShowImageCrop(true);
  };

  const handleCloseCard = (e) => {
    e.preventDefault();
    if (contactdataChanged || interactionDataChanged.length) {
      setShowCloseContactModal(true);
    } else {
      setShowContactShow(false);
    }
  }



  return (

    <div className="flex flex-row items-start justify-center bg-background-disabled p-5 w-[1200px] h-[710px] max-h-full rounded border-2 border-brand-primary">     
      <div className="flex flex-col items-center align-center bg-slate-200 p-5 h-full w-full b-r-1 border-solid border-brand-primary">
        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-row w-2/3">
            <div className="flex flex-row items-center jusitfy-center relative w-[100px] h-[100px] mr-8">
              {displayImage && <img className="object-contain rounded-full w-[100px] h-[100px]" onError={e => setDisplayImage(false)} src={currentContact.photoUrl ? currentContact.photoUrl : emptyPic} />}
              {!displayImage && <img className="object-contain rounded-full w-[100px] h-[100px]" src={emptyPic} />}

              <img onClick={imageClick} className="w-8 h-8 m-2 absolute right-0 bottom-0 cursor-pointer" src={uploadIcon} />
              <input type='file' onChange={handleFile} ref={inputFile} className="hidden" />
            </div>
            <div className="flex flex-col justify-evenly">
              <h2 className={sub2Bold}>{currentContact.name ? shortenText(currentContact.name, 50) : `Name`}</h2>
              <h3 className={sub2Med}>{currentContact.title ? shortenText(currentContact.title, 50) : `Title`}</h3>
              <h3 className={sub2Med}>{currentContact.company ? shortenText(currentContact.company, 50) : `Company`}</h3>
            </div>
          </div>
          <div className="flex flex-col w-1/3 items-end">
            <button className={BUTTON_CLASS_WHITE + "mb-3 w-1/2"} onClick={handleCloseCard}>Close</button>
            <ColumnChoiceDropdown contactId={contact.id} tiers={tiers} columnOrder={columnOrder} setColumnOrder={setColumnOrder}/>
          </div>
        </div>
        <div className="flex flex-row justify-start w-full">
          <div>
            <h4 onClick={chooseContent} className={contentChoice === CONTACT_INFO_TEXT ? UNDERLINE_STYLE : NON_UNDERLINE_STYLE}>{CONTACT_INFO_TEXT}</h4>
          </div>
          <div>
            <h4 onClick={chooseContent} className={contentChoice === INTERACTION_NOTES_TEXT ? UNDERLINE_STYLE : NON_UNDERLINE_STYLE}>{INTERACTION_NOTES_TEXT}</h4>
          </div>
          <div>
            <h4 onClick={chooseContent} className={contentChoice === FOLLOWUP_DRAFT_TEXT ? UNDERLINE_STYLE : NON_UNDERLINE_STYLE}>{FOLLOWUP_DRAFT_TEXT}</h4>
          </div>
        </div>
        {contentChoice == CONTACT_INFO_TEXT && <ContactUpdate contact={contact} columnOrder={columnOrder} setDataChanged={setContactDataChanged}/> }
        {contentChoice == INTERACTION_NOTES_TEXT && <InteractionIndex contact={contact} setDataChanged={setInteractionDataChanged}/>}
        {contentChoice == FOLLOWUP_DRAFT_TEXT && <FollowupDraft contact={contact}/>}
      </div>
      <Timeline contactId={contact.id}/>
      {showImageCrop && <Modal children={<ImageCrop image={photo} setShowImageCrop={setShowImageCrop} contact={contact}/>} />}
      {showCloseContactModal && <Modal children={<CloseContact setShowCloseContactModal={setShowCloseContactModal} setShowContactShow={setShowContactShow}/>} />}
    </div>
  )

}


export default ContactShow;