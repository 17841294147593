import toast from "react-hot-toast";
import { BUTTON_CLASS, BUTTON_CLASS_WHITE, HEADER_3 } from "../constants";


const ContactDiscard = ({setShowDiscardModal, setShowAddContact}) => {


  const handleDeleteContact = async e => {
    e.preventDefault();
    setShowDiscardModal(false);
    if (setShowAddContact) setShowAddContact(false);
    toast.success('Contact discarded.')
  }

  const handleCancel = e => {
    e.preventDefault();
    setShowDiscardModal(false);
  }

  return (

    <div className="flex flex-col justify-start bg-slate-200 p-5 h-full min-w-[500px] rounded border-2 border-brand-primary bg-white">
      <p className={HEADER_3}>Discard contact?</p>
      <p className="mb-[32px]">Any information you input will be lost.</p>
      <div className="flex flex-row justify-end">
        <button className={BUTTON_CLASS_WHITE + 'min-w-[100px] mr-[32px]'} onClick={handleCancel}>Cancel</button>
        <button className={BUTTON_CLASS + 'min-w-[150px]'} onClick={handleDeleteContact}>
          Discard contact
          </button>
      </div>
    </div>

  )

}

export default ContactDiscard;